import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'windi.css'
import 'vant/lib/button/style'
import 'vant/lib/index.css'
import 'normalize.css'
import '../src/assets/css/index.less'
router.beforeEach((to: any, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
createApp(App).use(store).use(router).mount('#app')

import { useRoute } from 'vue-router'
import { createStore } from 'vuex'
const route = useRoute()
export default createStore({
  state: {
    token: '' as any
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    }
  },
  getters: {
    getToken(state) {
      return state.token
    }
  },
  actions: {
    SetToken({ commit }, { token }) {
      commit('setToken', token)
    }
  },
  modules: {}
})

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/security/search.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/main',
    component: () => import('@/views/main/main.vue')
  },
  {
    path: '/list',
    component: () => import('@/views/wbt/list/index.vue')
  },
  {
    path: '/test',
    component: () => import('@/views/wbt/test.vue')
  },
  {
    path: '/culture/detail',
    component: () => import('@/views/culture/detail.vue'),
    meta: {
      title: '文版通拍品详情'
    }
  },
  {
    path: '/about',
    component: () => import('@/views/wbt/rule/about.vue'),
    meta: {
      title: '关于文版通'
    }
  },
  {
    path: '/qualification',
    component: () => import('@/views/wbt/qualification/index.vue'),
    meta: {
      title: '营业执照和平台资质'
    }
  },
  {
    path: '/qualification/show',
    component: () => import('@/views/wbt/qualification/show.vue'),
    meta: {
      title: '图片'
    }
  },
  {
    path: '/credit/product',
    component: () => import('@/views/mall/product/credit.vue'),
    meta: {
      title: '积分商品'
    }
  },
  {
    path: '/domain/business',
    component: () => import('@/views/domain/businessDetail.vue'),
    meta: {
      title: '业务变更通知'
    }
  },
  {
    path: '/domain/vipRight',
    component: () => import('@/views/domain/vipRight.vue'),
    meta: {
      title: '会员权益'
    }
  },
  {
    path: '/charity/article',
    component: () => import('@/views/charity/article/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/charity/rank',
    component: () => import('@/views/charity/rank/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/charity/introduction',
    component: () => import('@/views/charity/introduction/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/verify',
    component: () => import('@/views/security/index.vue'),
    meta: {
      title: '全国防伪溯源查询'
    }
  },
  {
    path: '/search',
    component: () => import('@/views/security/search.vue'),
    meta: {
      title: '全国防伪溯源查询'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
